import React from 'react';
import { jobType } from '../../types';


function StoreNumberCell({ job = {} }) {
  const { storeNumber } = job;
  return (
    <>
      {storeNumber}
    </>
  );
}

StoreNumberCell.propTypes = {
  job: jobType.isRequired,
};

export default StoreNumberCell;
