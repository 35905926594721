import { gql } from '@apollo/client';

export const getAllOrderedJobs = () => gql`
  query searchJobs(
    $pageSize: Int!, 
    $pageNumber: Int!, 
    $sortField: String!, 
    $sortDirection: String!, 
    $searchString: String, 
    $mvNumbers: [String], 
    $jobStatuses: [String], 
    $technicians: [String], 
    $storeNumbers: [Int], 
    $states: [String], 
    $serviceTypes: [String], 
    $siteCodes: [String], 
    $fromDate: String, 
    $toDate: String, 
    $sources: [String]) {
    searchJobs(
      pageSize: $pageSize,
      pageNumber: $pageNumber,
      sortField: $sortField,
      sortDirection: $sortDirection,
      includeFacets: false,
      searchString: $searchString,
      mvNumbers: $mvNumbers,
      jobStatuses: $jobStatuses,
      technicians: $technicians,
      storeNumbers: $storeNumbers,
      states: $states,
      serviceTypes: $serviceTypes,
      siteCodes: $siteCodes,
      fromDate: $fromDate,
      toDate: $toDate,
      sources: $sources
    ) {
    jobs {
        id
        customer {
          email
          firstName
          lastName
          phones {
            number
          }
        }
        aggregatedAttributes {
          oversizedVerticalFeet
        }
        address {
          line1
          line2
          city
          state
          zipcode
        }
        order {
          orderNumber
          date
          poNumbers
        }
        status
        siteCode
        serviceType
        hasJobBeenRead
        source
        storeNumber
        technicians
      }
      paginationMetadata {
        currentPage
        pageSize
        totalPageCount
        totalItemCount
      }
    }
  }
`;

export const getVendorsByUser = gql`
  query vendorsByUser($ldap: String!) {
    vendorsByUser(
      ldap: $ldap
    )
  }
`;
