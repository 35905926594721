const getPreviousStatus = (statusHistory) => {
  // Return null if there are fewer than two entries
  if (statusHistory.length < 2) return null;

  // Start from the second-to-last entry and work backwards
  let lastValidIndex = statusHistory.length - 2;

  while (lastValidIndex >= 0) {
    const lastStatus = statusHistory[lastValidIndex];

    // If the status is not 'changed', return it
    if (lastStatus.status !== 'changed') {
      return lastStatus.status;
    }

    // Move to the previous entry
    lastValidIndex -= 1;
  }

  // Return null if no valid status is found
  return null;
};

export default getPreviousStatus;
