import React, { useState } from 'react';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';
import { parseISO, addDays, subDays, format } from 'date-fns';
import { getAllOrderedJobs } from './queries';
import {
  InitialFromDate,
  InitialToDate,
} from './constants';

import {
  DateCell,
  UnscheduledOrderNumberCell,
  ServiceTypeCell,
  StatusCell,
  CustomerNameCell,
  AddressCell,
  PrimaryPhoneNoCell,
  ViewDetailsCell,
  SourceCell,
  Loader,
  Error, PaginationTable,
} from '../../shared/components';
import Dashboard from './Dashboard';
import Sidebar from './Sidebar';
import styles from './styles';
import StoreNumberCell from '../../shared/components/CellComponents/StoreNumberCell';
import TechniciansCell from '../../shared/components/CellComponents/TechniciansCell';

const filtersConfig = [
  {
    label: 'Sites',
  },
  {
    label: 'ServiceTypes',
  },
  {
    label: 'Vendors',
  },
  {
    label: 'JobStatuses',
  },
  {
    label: 'Technicians',
  },
  {
    label: 'StoreNumbers',
  },
  {
    label: 'Locations',
  },
  {
    label: 'Dates',
  },
];

function parseQueryString(location) {
  const qs = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const getQsFilter = field => (typeof qs[field] === 'undefined' ? [] : qs[field]);
  return filtersConfig
    .map(x => x.label)
    .reduce((accumulator, currentValue) => ({ ...accumulator, [currentValue]: getQsFilter(currentValue) }), {});
}

const tableConfig = [
  {
    id: 'date',
    columnName: 'Date',
    sortField: 'date',
    renderValue: job => <DateCell job={job} />,
  },
  {
    id: 'orderNumber',
    columnName: 'Order',
    sortField: 'orderNumber',
    renderValue: job => <UnscheduledOrderNumberCell job={job} isBold={false} />,
  },
  {
    id: 'serviceType',
    columnName: 'Type',
    sortField: 'serviceType',
    renderValue: job => <ServiceTypeCell job={job} />,
  },
  {
    id: 'source',
    columnName: 'Source',
    sortField: 'source',
    renderValue: job => <SourceCell job={job} />,
  },
  {
    id: 'storeNumber',
    columnName: 'Store No.',
    sortField: 'storeNumber',
    renderValue: job => <StoreNumberCell job={job} />,
  },
  {
    id: 'technicians',
    columnName: 'Technicians',
    renderValue: job => <TechniciansCell job={job} />,
  },
  {
    id: 'status',
    columnName: 'Status',
    sortField: 'status',
    renderValue: job => <StatusCell job={job} />,
  },
  {
    id: 'name',
    columnName: 'Name',
    sortField: 'name',
    renderValue: job => <CustomerNameCell job={job} />,
  },
  {
    id: 'location',
    columnName: 'Location',
    sortField: 'location',
    renderValue: job => <AddressCell job={job} />,
  },
  {
    id: 'phones',
    columnName: 'Primary No.',
    sortField: 'phones',
    renderValue: job => <PrimaryPhoneNoCell job={job} />,
  },
  {
    id: 'viewOrderDetails',
    columnName: '',
    renderValue: job => <ViewDetailsCell job={job} />,
  },
];

const useStyles = makeStyles(styles);

const JobsList = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const selectedSearchFilters = parseQueryString(location);
  const { pathname } = location;
  // Paging
  const [pageSize, setPageSize] = useState(50);
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  // Sidebar Filters
  const [serviceTypes, setServiceTypes] = useState(selectedSearchFilters.ServiceTypes);
  const [siteCodes, setSiteCodes] = useState(selectedSearchFilters.Sites);
  const [mvNumbers, setMvNumbers] = useState([]);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [technicians, setTechnicians] = useState(selectedSearchFilters.Technicians);
  const [storeNumbers, setStoreNumbers] = useState(selectedSearchFilters.StoreNumbers.map(storeNumber => parseInt(storeNumber, 10)));
  const [states, setStates] = useState(selectedSearchFilters.Locations);
  const [fromDate, setFromDate] = useState(format(addDays(parseISO(selectedSearchFilters.Dates[0] || InitialFromDate), 1), 'yyyy-MM-dd'));
  const [toDate, setToDate] = useState(format(addDays(parseISO(selectedSearchFilters.Dates[1] || InitialToDate), 1), 'yyyy-MM-dd'));

  const { searchTerm } = queryString.parse(location.search);
  const title = searchTerm ? `Search Results for ${searchTerm}` : 'All Jobs';


  function routeToFilter(filters) {
    const qs = filtersConfig.map(f => filters[f.label].map((x => `${f.label}[]=${x}`)).join('&'))
      .filter(x => x !== '')
      .join('&');

    const search = `?${qs}`;
    const historyParams = { pathname, search };

    history.push(historyParams);
  }


  const { loading, error, data, fetchMore, networkStatus } = useQuery(getAllOrderedJobs(), {
    variables: {
      pageSize: pageSize,
      pageNumber: 1,
      sortField: sortField,
      sortDirection: sortDirection,
      searchString: searchTerm,
      mvNumbers: mvNumbers,
      jobStatuses: jobStatuses,
      storeNumbers: storeNumbers,
      technicians: technicians,
      states: states,
      serviceTypes: serviceTypes,
      siteCodes: siteCodes,
      fromDate: fromDate,
      toDate: toDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingMoreJobs = networkStatus === NetworkStatus.fetchMore;
  if (loading && !loadingMoreJobs && !data) {
    return (
      <div className={classes.loadingContainer}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <Error
        error={error}
        message="Unable to process the jobs list. Please use the filter section to narrow your search and try again."
      />
    );
  }

  const {
    jobs,
    paginationMetadata,
  } = data.searchJobs;

  return (
    <div>
      <Dashboard
        left={(
          <Sidebar
            classes={classes}
            filtersConfig={filtersConfig}
            initialFromDate={fromDate}
            initialToDate={toDate}
            initialSiteCodes={siteCodes}
            initialServiceTypes={serviceTypes}
            initialStates={states}
            initialStatuses={jobStatuses}
            initialTechnicians={technicians}
            initialStoreNumbers={storeNumbers}
            onApplyClicked={(newSiteCodes, newServiceTypes, newMvNumbers, newJobStatuses, newTechnicians, newStoreNumbers, newStates, newFromDate, newToDate) => {
              // There is some existing bug in MUI DatePicker/MuiPickersUtilsProvider that is causing the date to be subtracted by 1 day
              const fromDateWithSubtractedDay = format(subDays(parseISO(newFromDate), 1), 'yyyy-MM-dd');
              const toDateWithSubtractedDay = format(subDays(parseISO(newToDate), 1), 'yyyy-MM-dd');
              // Update Url Query String
              const allFilters = {
                Sites: newSiteCodes,
                ServiceTypes: newServiceTypes,
                Vendors: newMvNumbers,
                JobStatuses: newJobStatuses,
                Technicians: newTechnicians,
                StoreNumbers: newStoreNumbers,
                Locations: newStates,
                Dates: [fromDateWithSubtractedDay, toDateWithSubtractedDay],
              };
              routeToFilter(allFilters);

              setSiteCodes(newSiteCodes);
              setServiceTypes(newServiceTypes);
              setMvNumbers(newMvNumbers);
              setJobStatuses(newJobStatuses);
              setTechnicians(newTechnicians);
              setStoreNumbers(newStoreNumbers);
              setStates(newStates);
              setFromDate(fromDateWithSubtractedDay);
              setToDate(newToDate);
              fetchMore({
                variables: {
                  pageNumber: 1,
                  serviceTypes: newServiceTypes,
                  siteCodes: newSiteCodes,
                  mvNumbers: newMvNumbers,
                  jobStatuses: newJobStatuses,
                  technicians: newTechnicians,
                  storeNumbers: newStoreNumbers,
                  states: newStates,
                  fromDate: fromDateWithSubtractedDay,
                  toDate: newToDate,
                },
              });
            }}
          />
        )}
        right={(
          <PaginationTable
            title={title}
            tableConfig={tableConfig}
            data={jobs}
            currentPage={paginationMetadata.currentPage}
            pageSize={pageSize}
            totalItemCount={paginationMetadata.totalItemCount}
            onChangePage={(page) => {
              fetchMore({
                variables: {
                  pageNumber: page,
                },
              });
            }}
            sortField={sortField}
            sortDirection={sortDirection}
            onChangeSortDirection={setSortDirection}
            onChangeSortField={setSortField}
            onChangePageSize={setPageSize}
            isLoading={loadingMoreJobs || loading}
          />
        )}
      />
    </div>
  );
};

export default JobsList;
