import React from 'react';
import { jobType } from '../../types';
import ArrayChunker from '../../utilities/arrayChunker';


function TechniciansCell({ job = {} }) {
  const { technicians = [] } = job;
  const groupedTechnicians = ArrayChunker(technicians, 2);


  return (
    <>
      {
        groupedTechnicians.map(group => (
          <div key={group}>
            {group.join(', ')}
          </div>
        ))
      }
    </>
  );
}

TechniciansCell.propTypes = {
  job: jobType.isRequired,
};

export default TechniciansCell;
