import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_JOB_BY_ID = gql`
  query jobById($id: String!) {
    jobById(id: $id) {
      source
      id
      status
      statusHistory {
        date
        status
      }
      attributes {
        ... on JobAttributesWindowTreatment {
          highestWindowHeight
          numberOfWindows
          windowCategories
          windowHaul
          windowRemoval
          yearBuilt
          tripCharge
        }
      }
      customer {
        id
        email
        firstName
        lastName
        phones {
          number
        }
      }
      address {
        id
        line1
        line2
        city
        state
        zipcode
      }
      changeOrder {
        charges {
          specId
          displayName
          value
          comment
        }
      }
      serviceType
      order {
        date
        orderNumber
        legacyOrderNumber
        poNumbers
      }
      pricingResult {
        charges {
          cost
          specId
          specType
          value
        }
        cost
        salesTax
      }
      initialPricingResult {
        charges {
          cost
          specId
          specType
          value
        }
        cost
        salesTax
      }
      storeNumber
      siteCode
      vendor {
        id
        mvNumber
        name
      }
      notes {
        id
        author
        content
        date
        authorRole
        closedBy
        closedOn
      }
      relatedJobs {
        id
        serviceType
        attributes {
          numberOfWindows
        }
        status
        order {
          orderNumber
          date
          poNumbers
        }
        vendor {
          id
          name
          mvNumber
          email
          phoneNumber
        }
      }
      documents {
        id
        filename
        dateUploaded
        formNumber
        uri
      }
      items {
        id
        label
        name
        type
        initialAttributes {
           ... on ItemAttributesWindowTreatment {
              category
            mountType
            # isOversizedForVendor
            # isCutout
            spacers
            holdDownBrackets
            height
            width
            depth
          }
        }
        attributes {
           ... on ItemAttributesWindowTreatment {
            category
            mountType
            # isOversizedForVendor
            # isCutout
            spacers
            holdDownBrackets
            height
            width
            depth
          }
        }
        displayAttributes {
          attributeKey
          value
          displayName
        }
        productInformation {
          configurationId
          productId
          omsId
          productName
          poNumber
          productPrice
          productSalesTax
          configurationDetails{
            displayName
            choices
          }
          isDelivered
        }
        photos {
          id
          url
        }
        notes {
          id
          author
          authorRole
          date
          content
        }
        flagNote {
          author
          authorRole
          date
          content
          reason
          measurements {
            width
            height
            depth
          }
        }
        parentId
        isInstalled
      }
      preferredAppointmentTimes {
        dateStart
        dateEnd
      }
      appointments {
        id
        dateStart
        dateEnd
        technicians
        status
      }
      aggregatedAttributes {
        numberOfWindows
        highestWindowHeight
        windowRemoval
        windowHaul
        yearBuilt
      }
      availableTechnicians
      availableTimeslots {
        dateStart
        dateEnd
      }
      pricingAgreementId
      reworkType
      pvNumber
    }
  }
`;

// ids had issues with cache breaking SPA - so we created another query with the ID that is not use other than JOB EDITOR
export const GET_JOB_BY_ID_JOB_EDITOR = gql`
  query jobById($id: String!) {
    jobById(id: $id) {
      id
      status
      statusHistory {
        date
        status
      }
      attributes {
        ... on JobAttributesWindowTreatment {
          highestWindowHeight
          numberOfWindows
          windowCategories
          windowHaul
          windowRemoval
          yearBuilt
        }
      }
      customer {
        id
        email
        firstName
        lastName
        phones {
          number
          canCall
          canText
        }
      }
      address {
        id
        line1
        line2
        city
        state
        zipcode
      }
      changeOrder {
        charges {
          specId
          displayName
          value
          comment
        }
      }
      serviceType
      order {
        date
        orderNumber
        poNumbers
      }
      pricingResult {
        charges {
          cost
          specId
          specType
          value
        }
        cost
        salesTax
      }
      initialPricingResult {
        charges {
          cost
          specId
          specType
          value
        }
        cost
        salesTax
      }
      storeNumber
      siteCode
      vendor {
        id
        mvNumber
        name
        email
        phoneNumber
      }
      notes {
        id
        author
        content
        date
        authorRole
      }
      relatedJobs {
        id
        serviceType
        attributes {
          numberOfWindows
        }
        status
        order {
          orderNumber
          date
          poNumbers
        }
        vendor {
          id
          name
          mvNumber
          email
          phoneNumber
        }
      }
      items {
        id
        label
        name
        type
        initialAttributes {
           ... on ItemAttributesWindowTreatment {
              category
            mountType
            # isOversizedForVendor
            # isCutout
            spacers
            holdDownBrackets
            height
            width
            depth
          }
        }
        attributes {
           ... on ItemAttributesWindowTreatment {
              category
            mountType
            # isOversizedForVendor
            # isCutout
            spacers
            holdDownBrackets
            height
            width
            depth
          }
        }
        displayAttributes {
          attributeKey
          value
          displayName
        }
        productInformation {
          configurationId
          productId
          omsId
          productName
          poNumber
          productPrice
          productSalesTax
          configurationDetails{
            displayName
            choices
          }
          isDelivered
        }
        photos {
          id
          url
        }
        notes {
          id
          author
          authorRole
          date
          content
        }
        flagNote {
          author
          authorRole
          date
          content
          reason
          measurements {
            width
            height
            depth
          }
        }
        parentId
        isInstalled
      }
      preferredAppointmentTimes {
        dateStart
        dateEnd
      }
      appointments {
        id
        dateStart
        dateEnd
        technicians
      }
      aggregatedAttributes {
        windowRemoval
        windowHaul
        yearBuilt
      }
      availableTechnicians
      availableTimeslots {
        dateStart
        dateEnd
      }
      pricingAgreementId
    }
  }
`;

export const GET_PRICING_AGREEMENT = gql`
  query getPricingAgreement($id: String!) {
      getPricingAgreement(id: $id) {
        specSheets{
          bands{
            bandName
          }
          minimumCost
          specId
          specType
        }
      }
  }
`;

export const JOB_WITH_UNREADNOTES_EXISTS = gql`
query jobWithUnreadNotesExists {
  jobWithUnreadNotesExists
}
`;
