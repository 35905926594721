import React from 'react';
import {
  func,
  string,
  arrayOf,
  shape,
  bool,
} from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

const renderHeaderCells = (tableConfig, sortField, sortDirection, onRequestSort) => {
  const createSortHandler = property => (event) => {
    onRequestSort(event, property);
  };

  return (
    tableConfig.map(config => (
      <TableCell
        key={config.id}
        sortDirection={sortField === config.sortField ? sortDirection : false}
      >
        <TableSortLabel
          active={sortField === config.sortField}
          onClick={createSortHandler(config.sortField)}
          direction={sortField === config.sortField ? sortDirection : 'asc'}
          disabled={config.sortField === undefined}
        >
          {config.columnName}
        </TableSortLabel>
      </TableCell>
    ))
  );
};

const Head = ({
  hasCheckboxSelection,
  tableConfig,
  sortField,
  sortDirection,
  onRequestSort,
  allSelected,
  onSelectAllClick,
}) => (
  <TableHead>
    <TableRow>
      {hasCheckboxSelection && (
        <TableCell padding="checkbox" id="do-not-print">
          <Checkbox
            checked={allSelected}
            onChange={event => onSelectAllClick(event.target.checked)}
          />
        </TableCell>
      )}
      {renderHeaderCells(tableConfig, sortField, sortDirection, onRequestSort)}
    </TableRow>
  </TableHead>
);

Head.propTypes = {
  hasCheckboxSelection: bool.isRequired,
  allSelected: bool.isRequired,
  onRequestSort: func.isRequired,
  onSelectAllClick: func.isRequired,
  sortDirection: string.isRequired,
  sortField: string.isRequired,
  tableConfig: arrayOf(shape({})).isRequired,
};

export default Head;
