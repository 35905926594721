import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 5,
  },
}));

function getStyles(listItem, selectedItem, theme) {
  return {
    fontWeight:
      selectedItem.indexOf(listItem) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Multiselect = ({ selectedItems, setSelectedItems, list, label, onDelete }) => {
  const classes = useStyles();
  const theme = useTheme();
  const handleChange = (event) => {
    setSelectedItems(event.target.value);
  };
  return (
    <FormControl className={classes.formControl}>
      {label && <InputLabel id="multiple-chip-label"><Typography>{label}</Typography></InputLabel>}
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={selected => (
          <div className={classes.chips}>
            {selected.map(value => (
              <Chip
                key={value}
                label={value}
                className={classes.chip}
                deleteIcon={<CancelIcon onMouseDown={event => event.stopPropagation()} />}
                onDelete={() => { onDelete(value); }}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {list.map(listItem => (
          <MenuItem key={listItem} value={listItem} style={getStyles(listItem, selectedItems, theme)}>
            {listItem}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Multiselect.propTypes = {
  list: PropTypes.array.isRequired,
  label: PropTypes.string,

  setSelectedItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
};

Multiselect.defaultProps = {
  label: '',
  onDelete: () => { },
};

export default Multiselect;
