import React, { useState, Fragment } from 'react';
import {
  string,
  func,
  object,
  bool,
  arrayOf,
} from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import ErrorIcon from '@material-ui/icons/Error';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useMutation } from '@apollo/client';
import omit from 'lodash/omit';

import { timeslotType, statusHistoryType } from '../../../shared/types';
import { RESCHEDULE_APPOINTMENT, CREATE_APPOINTMENT, JOB_STATUS, SET_JOB_ATTRIBUTES } from '../../../apollo/mutations';
import { GET_JOB_BY_ID } from '../../../apollo/queries';
import {
  formatDateTime,
  formatDateRange,
  formatTimezoneAgnosticDate,
} from '../../../shared/utilities';

import { LOGIN_USER, RESCHEDULE_REASON_CUSTOMER_NOT_AT_JOBSITE } from '../../../constants';
import Card from '../Card';
import SelectAlternateTime from './SelectAlternateTime';

import styles from './styles';
import SelectedChoice from '../../../shared/components/SelectedChoice';
import { Loader } from '../../../shared/components';
import LoadingDialog from '../../../shared/components/LoadingDialog';

const isSelectedDateRange = (dateRangeSelected, dateStart, dateEnd) => {
  if (!dateRangeSelected) return false;

  const { dateStart: start, dateEnd: end } = dateRangeSelected;

  return dateStart === start && dateEnd === end;
};

const renderPreferredTimes = (
  preferredTimes,
  dateRangeSelected,
  setDateRange,
  classes,
  isCreatingAppointment,
  isErrorForCreateAppointment,
  setIsErrorForCreateAppointment,
  source,
) => {
  if (!preferredTimes.length) {
    if (source === 'Instore') return <Typography variant="body1">Please call customer to schedule appointment time</Typography>;
    return <Typography variant="body1">No Preferred Times Selected</Typography>;
  }

  if (isCreatingAppointment) {
    return (
      <>
        <div className={classNames(classes.circularSpinnerContainer, classes.widthContainer)}>
          <Loader />
          <div className={classes.circularSpinnerText}>
            Confirming Arrival Time
          </div>
        </div>
      </>
    );
  }

  if (isErrorForCreateAppointment) {
    return (
      <div className={classNames(classes.errorMessageContainer, classes.widthContainer)}>
        <ErrorIcon color="error" />
        <DialogContentText className={classNames(classes.contentText, classes.errorMessageText)}>
          An unexpected error has occurred. Please try again.
        </DialogContentText>
        <Button
          variant="contained"
          className={classes.confirmButton}
          color="primary"
          classes={{ label: classes.buttonLabel }}
          fullWidth
          onClick={() => setIsErrorForCreateAppointment(false)}
        >
          TRY AGAIN
        </Button>
      </div>
    );
  }

  return (
    <>
      {!isCreatingAppointment && !isErrorForCreateAppointment && (
        preferredTimes.map(({ dateEnd, dateStart }) => {
          const isSelected = isSelectedDateRange(dateRangeSelected, dateStart, dateEnd);
          const setRange = () => setDateRange({ dateStart, dateEnd });

          return (
            <Button
              variant="outlined"
              key={dateStart}
              className={classes.preferredTimesButton}
              classes={{
                label: classes.buttonLabel,
                root: isSelected ? classes.selected : '',
              }}
              fullWidth
              onClick={setRange}
            >
              {formatDateRange(dateStart, dateEnd)}
            </Button>
          );
        }))
      }
    </>
  );
};

const renderConfirmPrompt = (dateRangeSelected, handleDatesConfirmed, classes, isConfirmArrivalTimeButtonDisabled, hasCreateAppointmentErrored) => {
  if (!dateRangeSelected) return null;

  const setAppointmentDates = () => handleDatesConfirmed(dateRangeSelected);

  return (
    <Fragment>
      {!hasCreateAppointmentErrored && (
        <Typography variant="body2" className={classes.confirmText}>
          Confirming the appointment time will send an email to the customer.
        </Typography>
      )}
      {!hasCreateAppointmentErrored && (
        <Button
          variant="contained"
          className={classes.confirmButton}
          color="primary"
          classes={{ label: classes.buttonLabel }}
          fullWidth
          disabled={isConfirmArrivalTimeButtonDisabled}
          onClick={debounce(setAppointmentDates, 1000)}
        >
          Confirm Arrival Time
        </Button>
      )}
    </Fragment>
  );
};

const getDateToShow = (
  isCompleted,
  statusHistory,
  scheduledStart,
  scheduledEnd,
) => {
  if (isCompleted) {
    const completedStatuses = statusHistory.filter(({ status }) => status === 'completed');
    const completedStatusesOrderedByDate = orderBy(completedStatuses, ['date'], ['desc']);
    const mostRecentCompletedStatus = get(completedStatusesOrderedByDate, '0', {});

    return formatDateTime(mostRecentCompletedStatus.date);
  }

  return formatDateRange(scheduledStart, scheduledEnd);
};

const renderContent = (
  isDisabled,
  isCompleted,
  statusHistory,
  hasAppointment,
  scheduledStart,
  scheduledEnd,
  preferredAppointmentTimes,
  dateRangeSelected,
  setDateRange,
  handleDatesConfirmed,
  classes,
  isCreatingAppointment,
  isErrorForCreateAppointment,
  setIsErrorForCreateAppointment,
  isConfirmArrivalTimeButtonDisabled,
  source,
) => {
  if (isDisabled) {
    return null;
  }

  if (hasAppointment) {
    const dateSelectionClasses = classNames(
      classes.dateSelection,
      {
        [classes.scheduledBorder]: !isCompleted,
        [classes.completedBorder]: isCompleted,
      },
    );

    return (
      <Fragment>
        <Typography variant="subtitle1" className={classes.header}>
          {isCompleted ? 'Completed On' : 'Arrival Time Confirmed'}
        </Typography>
        <SelectedChoice
          icon={<CheckCircleOutlineIcon className={classes.icon} />}
          borderClassName={dateSelectionClasses}
          label={getDateToShow(isCompleted, statusHistory, scheduledStart, scheduledEnd)}
        />
      </Fragment>
    );
  }
  return (
    <Fragment>
      {source !== 'Instore' &&
        (
          <Typography variant="subtitle1" className={classes.header}>
            Confirm Arrival Time
          </Typography>
        )
      }
      {renderPreferredTimes(
        preferredAppointmentTimes,
        dateRangeSelected,
        setDateRange,
        classes,
        isCreatingAppointment,
        isErrorForCreateAppointment,
        setIsErrorForCreateAppointment,
        source,
      )}
      {renderConfirmPrompt(dateRangeSelected, handleDatesConfirmed, classes, isConfirmArrivalTimeButtonDisabled, isErrorForCreateAppointment)}
    </Fragment>
  );
};

const renderSelectAlternateTimeButton = (
  availableTimeslots,
  openDialog,
  isDisabled,
  classes,
  source,
  hasAppointment,
) => {
  const noAvailableTimeslots = !availableTimeslots || !availableTimeslots.length;

  let SelectAlternateTimeButton;
  if (source === 'Instore') {
    if (!hasAppointment) {
      SelectAlternateTimeButton = (
        <Button
          variant="contained"
          className={classes.confirmButton}
          color="primary"
          classes={{ label: classes.buttonLabel }}
          onClick={openDialog}
          fullWidth
        >
          Select Arrival Day / Time
        </Button>
      );
    } else {
      SelectAlternateTimeButton = (
        <Button
          variant="contained"
          className={classes.confirmButton}
          color="primary"
          classes={{ label: classes.buttonLabel }}
          onClick={openDialog}
          fullWidth
        >
          Edit Arrival Day And Time
        </Button>
      );
    }
  } else {
    SelectAlternateTimeButton = (
      <Button
        variant="text"
        onClick={openDialog}
        classes={{ label: classes.buttonLabel }}
        className={classes.toggleDialogButton}
        fullWidth
      >
        <Typography variant="body1" className={classes.toggleDialogButtonText}>
          Select an Alternate Time
        </Typography>
      </Button>
    );
  }
  if (noAvailableTimeslots) {
    return (
      <Tooltip title="No available timeslots">
        <div className={classes.disabledButtonWrapper}>
          {SelectAlternateTimeButton}
        </div>
      </Tooltip>
    );
  }

  return SelectAlternateTimeButton;
};

const renderCalendarDialog = (
  isDisabled,
  onDialogClose,
  openDialog,
  closeDialog,
  isDialogOpen,
  fullScreen,
  handleDatesConfirmed,
  availableTimeslots,
  classes,
  isSubmittingAppointment,
  isErrorForAppointmentDialog,
  setIsErrorForAppointmentDialog,
  source,
  hasAppointment,
  preferredAppointmentTimes,
  appointmentStatus,
  scheduledStart,
  canReschedule,
  setCanReschedule,
  rescheduleReason,
  setRescheduleReason,

) => {
  if (isDisabled) return null;

  return (
    <Fragment>
      {renderSelectAlternateTimeButton(availableTimeslots, openDialog, isDisabled, classes, source, hasAppointment)}
      <Dialog
        aria-labelledby="select-alternate-dates"
        aria-describedby="Select alternate dates"
        maxWidth="sm"
        fullScreen={fullScreen}
        open={isDialogOpen}
        onClose={onDialogClose}
      >
        <SelectAlternateTime
          cancelDialog={closeDialog}
          isSubmittingAppointment={isSubmittingAppointment}
          isErrorForAppointmentDialog={isErrorForAppointmentDialog}
          setIsErrorForAppointmentDialog={setIsErrorForAppointmentDialog}
          selectAlternateTime={handleDatesConfirmed}
          availableTimeslots={availableTimeslots}
          preferredAppointmentTimes={preferredAppointmentTimes}
          hasAppointment={hasAppointment}
          appointmentStatus={appointmentStatus}
          scheduledStart={scheduledStart}
          canReschedule={canReschedule}
          setCanReschedule={setCanReschedule}
          rescheduleReason={rescheduleReason}
          setRescheduleReason={setRescheduleReason}
        />
      </Dialog>
    </Fragment>
  );
};

const MarkAsDelivered = ({
  isDisabled,
  classes,
  jobId,
  isDeadPool,
}) => {
  const [updateJobStatusMutation] = useMutation(JOB_STATUS);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (isDisabled) {
    return null;
  }

  function handleConfirmDelivery() {
    setLoading(true);
    updateJobStatusMutation({
      variables: {
        setJobStatusInput: {
          jobId: jobId,
          status: 'pending-schedule',
          updatedBy: LOGIN_USER(),
        },
      },
    }).then((response) => {
      setLoading(false);
      if (response.errors) {
        setError(response.errors);
      } else {
        setIsDialogOpen(false);
      }
    }).catch(() => {
      // Handle 400 and 500 here? show try again here as well
      setLoading(false);
      setError('An error occured.');
    });
  }

  function reset() {
    setError(null);
  }

  function handleCancel() {
    setIsDialogOpen(false);
    reset();
  }

  return (
    <Fragment>
      <Typography variant="body1">Your products are being manufactured</Typography>
      <Button
        variant="outlined"
        className={classes.markAsDeliveredButton}
        color="primary"
        classes={{ label: classes.buttonLabel }}
        onClick={() => setIsDialogOpen(true)}
        disabled={isDeadPool}
      >
        Mark Products As Delivered
      </Button>

      <LoadingDialog
        isLoading={loading}
        error={error}
        isOpen={isDialogOpen}
        isDialogSubmitButtonDisabled={false}
        dialogTitleText="Product Delivery Confirmation"
        dialogSubmitButtonText="Confirm Delivery"
        dialogLoadingText="Cancel"
        onErrorTryAgain={reset}
        onDialogClose={handleCancel}
        onDialogSubmit={handleConfirmDelivery}
        dialogContent={(
          <DialogContentText>
            Please confirm that all products have been delivered and this job is ready for install to be scheduled.
          </DialogContentText>
        )}
      />
    </Fragment>
  );
};

MarkAsDelivered.propTypes = {
  isDisabled: bool,
  classes: object.isRequired,
  jobId: string.isRequired,
  isDeadPool: bool,
};

MarkAsDelivered.defaultProps = {
  isDisabled: true,
  isDeadPool: false,
};

const Scheduling = ({
  scheduledStart,
  scheduledEnd,
  jobId,
  appointmentId,
  vendorMVNumber,
  preferredAppointmentTimes = [],
  availableTimeslots = [],
  source,
  jobStatus = '',
  statusHistory,
  classes,
  serviceType,
  isDeadPool,
  appointmentStatus,
  onAppointmentUpdate,
  jobAttributes,
}) => {
  const [rescheduleAppointmentMutation] = useMutation(RESCHEDULE_APPOINTMENT);
  const [createAppointmentMutation] = useMutation(CREATE_APPOINTMENT);
  const [updateJobStatusMutation] = useMutation(JOB_STATUS);
  const [setJobAttributesMutation] = useMutation(SET_JOB_ATTRIBUTES);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [dateRangeSelected, setDateRange] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [isSubmittingAppointment, setIsSubmittingAppointment] = useState(false);
  const [isCreatingAppointment, setIsCreatingAppointment] = useState(false);

  const [isErrorForAppointmentDialog, setIsErrorForAppointmentDialog] = useState(false);
  const [isErrorForCreateAppointment, setIsErrorForCreateAppointment] = useState(false);

  const isConfirmArrivalTimeButtonDisabled = isErrorForCreateAppointment || isCreatingAppointment;

  const [canReschedule, setCanReschedule] = useState(false);
  const [rescheduleReason, setRescheduleReason] = useState('');

  const onDialogClose = (_, reason) => {
    if (isSubmittingAppointment && reason === 'backdropClick') {
      return;
    }
    setIsDialogOpen(false);
  };
  const hasAppointment = scheduledStart && scheduledEnd;

  const handleCreateMutation = (dateStart, dateEnd, setProgress, setError) => {
    createAppointmentMutation({
      variables: {
        createAppointmentInput: {
          jobId,
          dateStart: formatTimezoneAgnosticDate(dateStart),
          dateEnd: formatTimezoneAgnosticDate(dateEnd),
          dateCreated: new Date().toISOString(),
          mvNumber: vendorMVNumber,
        },
        setJobStatusInput: {
          jobId,
          status: 'pending-assignment',
          updatedBy: LOGIN_USER(),
        },
      },
      update(cache, { data: { createAppointment } }) {
        const { jobById } = cache.readQuery({
          query: GET_JOB_BY_ID, variables: { id: jobId },
        });

        const { appointments = [] } = jobById;
        cache.writeQuery({
          query: GET_JOB_BY_ID,
          data: { jobById: { ...jobById, appointments: appointments.concat(createAppointment) } },
        });
      },
    }).then((response) => {
      setProgress(false);

      if (response.errors) {
        setError(true);
      }
      else {
        if (isDialogOpen) {
          setIsDialogOpen(false);
        }
        onAppointmentUpdate();
      }
    }).catch(() => {
      //  Handle 500 here? show try again here as well
      setProgress(false);
      setError(true);
    });
  };

  const addTripCharge = async (setProgress, setError) => {
    try {
      const tripCharge = jobAttributes.tripCharge;
      const newJobAttributes = !tripCharge ? { ...jobAttributes, tripCharge: 1 } : { ...jobAttributes, tripCharge: tripCharge + 1 };
      const cleanedAttributes = omit(newJobAttributes, ['__typename']);
      setProgress(true);

      const response = await setJobAttributesMutation({
        variables: {
          setJobAttributesInput: {
            jobId: jobId,
            jobAttributes: cleanedAttributes,
            attributesToUpdate: ['tripCharge'],
          },
        },
      });

      setProgress(false);

      if (response.errors) {
        setError(true);
      } else if (isDialogOpen) {
        setIsDialogOpen(false);
      }
    } catch (error) {
      setProgress(false);
      setError(true);
    }
  };


  const handleUpdateMutation = async (dateStart, dateEnd, setProgress, setError) => {
    try {
      setProgress(true);

      const variables = {
        rescheduleAppointmentInput: canReschedule ? {
          appointmentId,
          newDateStart: formatTimezoneAgnosticDate(dateStart),
          newDateEnd: formatTimezoneAgnosticDate(dateEnd),
          rescheduleHistory: {
            previousAppointmentDateStart: formatTimezoneAgnosticDate(scheduledStart),
            previousAppointmentDateEnd: formatTimezoneAgnosticDate(scheduledEnd),
            rescheduledOn: new Date().toISOString(),
            rescheduleReason: rescheduleReason,
            rescheduledBy: LOGIN_USER(),
          },
        } : {
          appointmentId,
          newDateStart: formatTimezoneAgnosticDate(dateStart),
          newDateEnd: formatTimezoneAgnosticDate(dateEnd),
        },
        assignTechniciansInput: {
          appointmentId,
          technicians: [],
        },
        setJobStatusInput: {
          jobId,
          status: 'pending-assignment',
          updatedBy: LOGIN_USER(),
        },
      };

      const response = await rescheduleAppointmentMutation({ variables });

      setProgress(false);

      if (response.errors) {
        setError(true);
      } else {
        if (rescheduleReason === RESCHEDULE_REASON_CUSTOMER_NOT_AT_JOBSITE) {
          await addTripCharge(setProgress, setError);
        }
        onAppointmentUpdate();
        setIsDialogOpen(false);
      }
    } catch (error) {
      setProgress(false);
      setError(true);
    } finally {
      setRescheduleReason('');
    }
  };

  const handleDatesConfirmed = async ({ dateStart, dateEnd }) => {
    isDialogOpen ? setIsSubmittingAppointment(true) : setIsCreatingAppointment(true);
    if (!isDialogOpen) {
      handleCreateMutation(dateStart, dateEnd, setIsCreatingAppointment, setIsErrorForCreateAppointment);
    }
    else if (hasAppointment) {
      await handleUpdateMutation(dateStart, dateEnd, setIsSubmittingAppointment, setIsErrorForAppointmentDialog);
    }
    else {
      handleCreateMutation(dateStart, dateEnd, setIsSubmittingAppointment, setIsErrorForAppointmentDialog);
    }
  };

  const openDialog = () => {
    setIsDialogOpen(true);
    setRescheduleReason('');
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const isCompleted = jobStatus.toLowerCase() === 'completed';
  const isPendingProducts = jobStatus.toLowerCase() === 'pending-products';
  const isInstallPendingProducts = serviceType === 'installation' && isPendingProducts;

  return (
    <Card title="Scheduling">
      <div className={classes.wrapper}>
        {renderContent(
          isInstallPendingProducts,
          isCompleted,
          statusHistory,
          hasAppointment,
          scheduledStart,
          scheduledEnd,
          preferredAppointmentTimes,
          dateRangeSelected,
          setDateRange,
          handleDatesConfirmed,
          classes,
          isCreatingAppointment,
          isErrorForCreateAppointment,
          setIsErrorForCreateAppointment,
          isConfirmArrivalTimeButtonDisabled,
          source,
        )}
        {renderCalendarDialog(
          isCompleted || isInstallPendingProducts,
          onDialogClose,
          openDialog,
          closeDialog,
          isDialogOpen,
          fullScreen,
          handleDatesConfirmed,
          availableTimeslots,
          classes,
          isSubmittingAppointment,
          isErrorForAppointmentDialog,
          setIsErrorForAppointmentDialog,
          source,
          hasAppointment,
          preferredAppointmentTimes,
          appointmentStatus,
          scheduledStart,
          canReschedule,
          setCanReschedule,
          rescheduleReason,
          setRescheduleReason,
        )}
      </div>
      <MarkAsDelivered
        isDisabled={!isInstallPendingProducts}
        classes={classes}
        jobId={jobId}
        updateJobStatusMutation={updateJobStatusMutation}
        isDeadPool={isDeadPool}
      />
    </Card>
  );
};

Scheduling.propTypes = {
  scheduledStart: string,
  scheduledEnd: string,
  appointmentId: string,
  jobId: string.isRequired,
  vendorMVNumber: string,
  preferredAppointmentTimes: arrayOf(timeslotType),
  availableTimeslots: arrayOf(timeslotType),
  jobStatus: string.isRequired,
  statusHistory: statusHistoryType.isRequired,
  classes: object.isRequired,
  source: string,
  serviceType: string,
  isDeadPool: bool,
  appointmentStatus: string,
  onAppointmentUpdate: func.isRequired,
  jobAttributes: object.isRequired,
};

Scheduling.defaultProps = {
  scheduledStart: '',
  scheduledEnd: '',
  appointmentId: '',
  vendorMVNumber: '',
  preferredAppointmentTimes: [],
  availableTimeslots: [],
  source: '',
  serviceType: '',
  isDeadPool: false,
  appointmentStatus: '',
};

const EnhancedScheduling = withStyles(styles)(props => (
  <Scheduling
    {...props}
    rescheduleAppointmentMutation={useMutation(RESCHEDULE_APPOINTMENT)[0]}
    createAppointmentMutation={useMutation(CREATE_APPOINTMENT)[0]}
    updateJobStatusMutation={useMutation(JOB_STATUS)[0]}
    setJobAttributesMutation={useMutation(SET_JOB_ATTRIBUTES)[0]}
  />
));

export default EnhancedScheduling;
