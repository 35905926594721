import React, { useState } from 'react';
import {
  arrayOf,
  string,
} from 'prop-types';
import Moment from 'moment';
import uuidv4 from 'uuid/v4';
import { useMutation } from '@apollo/client';
import { ADD_NOTE_TO_JOB, CLOSE_NOTE_FOR_JOB, JOB_STATUS } from 'apollo/mutations';
import { GET_JOB_BY_ID } from 'apollo/queries';
import { IconButton, TextField, Chip } from '@material-ui/core';

import AddCircle from '@material-ui/icons/AddCircle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { noteType, statusHistoryType } from 'shared/types';
import { getChangeOrderNoteId, getPreviousStatus, isChangeOrderNote, isInStoreRole } from 'shared/utilities';
import Card from '../Card';
import styles from './styles';
import NoteItemContent from './JobNoteContent';
import { LOGIN_USER } from '../../../constants';
import LoadingDialog from '../../../shared/components/LoadingDialog';
import { JOB_WITH_UNREADNOTES_EXISTS } from '../../../apollo/queries';

const useStyles = makeStyles(styles);

const JobNotes = ({ allNotes, jobId, status, statusHistory, source, serviceType }) => {
  // Hooks
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [addNoteToJob, { error: errorOnAdd, loading: loadingAdd, reset: resetAdd }] = useMutation(ADD_NOTE_TO_JOB, {
    errorPolicy: 'none',
  });
  const [closeNoteForJob, { /* error, loading, */ reset }] = useMutation(CLOSE_NOTE_FOR_JOB, {
    errorPolicy: 'none',
  });
  const [updateJobStatus, { error: errorOnAckChange, loading: loadingAckChange, reset: resetAckChange }] = useMutation(JOB_STATUS, {
    errorPolicy: 'none',
  });

  // Derived State
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [isAckChangeDialogOpen, setIsAckChangeDialogOpen] = useState(false);

  // Functions
  const openAddNoteDialog = () => {
    setIsAddDialogOpen(true);
  };

  const closeAddNoteDialog = () => {
    setNoteContent('');
    resetAdd();
    setIsAddDialogOpen(false);
  };

  const openAckChangeDialog = () => {
    setIsAckChangeDialogOpen(true);
  };

  const closeAckChangeDialog = () => {
    resetAckChange();
    setIsAckChangeDialogOpen(false);
  };

  const isValidChangeOrder = (jobStatus, noteAuthor, noteAuthorRole, jobSource) => (
    jobStatus &&
    jobStatus.toLowerCase() === 'changed' &&
    isChangeOrderNote(noteAuthor, noteAuthorRole, jobSource)
  );

  const shouldCountAsUnreadNote = (note, jobSource, jobStatus) => {
    const isChangeOrder = isChangeOrderNote(note.author, note.authorRole, jobSource);
    if (isChangeOrder) {
      if (isValidChangeOrder(jobStatus, note.author, note.authorRole, jobSource)) {
        return !note.closedOn;
      } else {
        return false;
      }
    }
    if (isInStoreRole(note.authorRole) && !isChangeOrder) {
      return !note.closedOn;
    }
    return false;
  };

  const unreadCount = (
    <div className={classes.unreadChipWrapper}>
      <Chip
        label={`${allNotes ? allNotes.filter(note => shouldCountAsUnreadNote(note, source, status)).length : 0} Unread`}
        className={classes.unreadChip}
        variant="outlined"
      />
    </div>
  );

  const actionsConfig = [
    {
      id: 'add-note',
      renderAction: () => (
        <IconButton onClick={openAddNoteDialog} className={classes.addNoteButton}>
          <AddCircle className={classes.icon} />
        </IconButton>
      ),
    },
  ];

  const submitJobNote = () => {
    addNoteToJob({
      variables: {
        addNoteInput: {
          jobId,
          noteId: uuidv4(),
          author: LOGIN_USER(),
          authorRole: 'Admin',
          date: new Moment().format('YYYY-MM-DDTHH:mm:ss'),
          content: noteContent,
        },
      },
      update: (cache, { data: { addNote } }) => {
        const { jobById } = cache.readQuery({
          query: GET_JOB_BY_ID, variables: { id: jobId },
        });

        const { notes } = jobById;
        cache.writeQuery({
          query: GET_JOB_BY_ID,
          data: {
            jobById: {
              ...jobById,
              notes: [addNote, ...notes],
            },
          },
        });
      },
      onCompleted: () => {
        closeAddNoteDialog();
      },
    });
  };

  const submitCloseNote = (noteId) => {
    closeNoteForJob({
      variables: {
        closeNoteInput: {
          jobId,
          noteId,
          closedBy: LOGIN_USER(),
          closedOn: new Moment().format('YYYY-MM-DDTHH:mm:ss'),
        },
      },
      update: (cache) => {
        const { jobById } = cache.readQuery({
          query: GET_JOB_BY_ID, variables: { id: jobId },
        });

        const { notes: updatedNotes } = jobById;
        cache.writeQuery({
          query: GET_JOB_BY_ID,
          data: {
            jobById: {
              ...jobById,
              notes: updatedNotes,
            },
          },
        });
      },
      refetchQueries: [
        { query: JOB_WITH_UNREADNOTES_EXISTS },
      ],
      onCompleted: () => {
        reset();
      },
    });
  };

  const ackChangeOrder = () => {
    const previousStatus = getPreviousStatus(statusHistory) || (serviceType.toLowerCase() === 'measurement' ? 'pending-schedule' : 'pending-products');
    updateJobStatus({
      variables: {
        setJobStatusInput: {
          jobId,
          status: previousStatus,
          updatedBy: LOGIN_USER(),
        },
      },
    });
    submitCloseNote(getChangeOrderNoteId(allNotes, source));
    closeAckChangeDialog();
  };

  return (
    <Card title="Job Notes" actionsConfig={actionsConfig} subtitle={unreadCount}>
      <div className={classes.container}>
        <NoteItemContent notes={allNotes} status={status} source={source} onCloseNote={submitCloseNote} onClickAckChange={openAckChangeDialog} />
      </div>
      <LoadingDialog
        isLoading={loadingAdd}
        error={errorOnAdd}
        isOpen={isAddDialogOpen}
        isDialogSubmitButtonDisabled={noteContent.length === 0}
        dialogTitleText="Add Note"
        dialogSubmitButtonText="Add"
        dialogLoadingText="Adding Note"
        onErrorTryAgain={() => resetAdd()}
        onDialogClose={closeAddNoteDialog}
        onDialogSubmit={submitJobNote}
        fullScreen={fullScreen}
        dialogContent={(
          <>
            <div className={classes.dialogContainer}>
              <TextField
                autoFocus
                margin="dense"
                id="note"
                label="Note content..."
                type="text"
                fullWidth
                multiline
                rowsMax={5}
                value={noteContent}
                onChange={event => setNoteContent(event.target.value)}
              />
            </div>
          </>
        )}
      />
      <LoadingDialog
        isLoading={loadingAckChange}
        error={errorOnAckChange}
        isOpen={isAckChangeDialogOpen}
        isDialogSubmitButtonDisabled={false}
        dialogTitleText="Change Order Confirmation"
        dialogSubmitButtonText="Confirm"
        dialogLoadingText="Acknowledging Change Order"
        onErrorTryAgain={() => resetAckChange()}
        onDialogClose={closeAckChangeDialog}
        onDialogSubmit={ackChangeOrder}
        fullScreen={fullScreen}
        dialogContent={(
          <>
            <div className={classes.dialogContainer}>
              There has been a change on this order. Please confirm acknowledgment of the change.
            </div>
          </>
        )}
      />
    </Card>
  );
};

JobNotes.propTypes = {
  allNotes: arrayOf(noteType),
  jobId: string.isRequired,
  status: string,
  statusHistory: statusHistoryType,
  source: string.isRequired,
  serviceType: string.isRequired,
};

JobNotes.defaultProps = {
  allNotes: [],
  status: '',
  statusHistory: [],
};

export default JobNotes;
