/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    borderRadius: 5,
  },
}));


const AutoCompleteMultiSelect = ({ selectedItems, setSelectedItems, list, label, placeholder }) => {
  const classes = useStyles();

  const handleChange = (event, selectedValues) => {
    setSelectedItems(selectedValues);
  };

  return (
    <FormControl className={classes.formControl}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={list}
        value={selectedItems}
        onChange={handleChange}
        renderTags={(tagValue, getTagProps) => tagValue.map((value, index) => (
          <Chip
            {...getTagProps({ index })}
            key={value}
            label={value}
            className={classes.chip}
          />
        ))}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard" // TODO: Check this
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </FormControl>
  );
};

AutoCompleteMultiSelect.propTypes = {
  list: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,

  setSelectedItems: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
};

export default AutoCompleteMultiSelect;
