import React, { Fragment } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import compose from 'lodash/flowRight';
import { useHistory } from 'react-router-dom';
import { object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Badge } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NavMenu from '../../components/NavMenu';
import img from '../../assets/images/hd-logo-white.png';
import { TOKEN, LOGIN_USER } from '../../constants';
import Search from './Search';
import styles from './styles';
import { JOB_WITH_UNREADNOTES_EXISTS } from '../../apollo/queries';

function renderLogout(classes, history) {
  const client = useApolloClient();
  if (!TOKEN()) return null;

  return (
    <Fragment>
      <Typography color="inherit" className={classes.user}>
        {LOGIN_USER()}
      </Typography>
      <Button
        color="inherit"
        onClick={() => {
          localStorage.clear();
          client.resetStore();
          history.push('/login');
        }}
      >
        Log Out
      </Button>
    </Fragment>
  );
}

function renderLoginToolbar(classes) {
  if (!TOKEN()) return null;

  return (
    <Toolbar id="do-not-print">
      <NavMenu />
      <Typography
        className={classes.title}
        variant="h6"
        color="inherit"
        noWrap
      >
        Orders
      </Typography>
      <div className={classes.grow} />
      <div className={classes.stack}>
        <Search />
        <Typography
          className={classes.filterMessage}
          color="inherit"
          noWrap
        >
          Search results include filters
        </Typography>
      </div>
    </Toolbar>
  );
}

function Header({ classes }) {
  const history = useHistory();
  const { data } = useQuery(JOB_WITH_UNREADNOTES_EXISTS, { skip: !TOKEN() });

  const hasUnreadNotes = data && data.jobWithUnreadNotesExists;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.mainToolBar}>
          <img src={img} alt="Logo" className={classes.logo} />
          <Typography variant="h6" color="inherit" className={classes.grow}>
            My Home
          </Typography>
          {renderLogout(classes, history)}
          {TOKEN() && (
            <Button
              color="inherit"
              disabled={!hasUnreadNotes}
              onClick={() => {
                if (history.location.pathname.toLocaleLowerCase() !== '/unread-note-jobs') {
                  history.push('unread-note-jobs');
                }
              }}
            >
              <Badge
                color="primary"
                variant="dot"
                overlap="circular"
                badgeContent=" "
                className={classes.notificationBadge}
                invisible={!hasUnreadNotes}
              >
                <NotificationsIcon />
              </Badge>
            </Button>
          )}
        </Toolbar>
        {renderLoginToolbar(classes)}
      </AppBar>
    </div>
  );
}

Header.propTypes = {
  classes: object.isRequired,
};


const EnhancedHeader = compose(withStyles(styles))(Header);

export default EnhancedHeader;
