import { gql } from '@apollo/client';

export const ADD_NOTE_TO_JOB = gql`
  mutation addNoteToJob($addNoteInput: AddNoteInput!) {
    addNote(addNoteInput: $addNoteInput) {
      id
      author
      authorRole
      date
      content
      closedBy
      closedOn
    }
  }
`;

export const CLOSE_NOTE_FOR_JOB = gql`
  mutation closeNote($closeNoteInput: CloseNoteInput!) {
    closeNote(closeNoteInput: $closeNoteInput) {
      id
      closedBy
      closedOn
    }
  }
`;

export const UPDATE_JOB_EDITOR = gql`
  mutation updateJobByDevInput($updateJobByDevInput: UpdateJobByDevInput!) {
    updateJobByDev(updateJobByDevInput: $updateJobByDevInput) {
      id
    }
  }
`;

export const ASSIGN_TECHNICIANS_TO_APPOINTMENT = gql`
  mutation assignTechnicians(
    $assignTechniciansInput: AssignTechniciansInput!,
    $setJobStatusInput: SetJobStatusInput!
  ) {
    assignTechnicians(assignTechniciansInput: $assignTechniciansInput) {
      id
      technicians
    }

    setJobStatus(setJobStatusInput: $setJobStatusInput) {
      id
      status
      statusHistory {
        status
        date
      }
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleAppointment(
    $rescheduleAppointmentInput: RescheduleAppointmentInput!,
    $assignTechniciansInput: AssignTechniciansInput!,
    $setJobStatusInput: SetJobStatusInput!
  ) {
    rescheduleAppointment(rescheduleAppointmentInput: $rescheduleAppointmentInput) {
      id
      dateStart
      dateEnd
    }

    assignTechnicians(assignTechniciansInput: $assignTechniciansInput) {
      id
      technicians
    }

    setJobStatus(setJobStatusInput: $setJobStatusInput) {
      id
      status
      statusHistory {
        status
        date
      }
    }
  }
`;

export const CREATE_APPOINTMENT = gql`
  mutation createAppointment(
    $createAppointmentInput: CreateAppointmentInput!,
    $setJobStatusInput: SetJobStatusInput!
  ) {
    createAppointment(createAppointmentInput: $createAppointmentInput) {
      id
      dateStart
      dateEnd
      technicians
    }

    setJobStatus(setJobStatusInput: $setJobStatusInput) {
      id
      status
      statusHistory {
        status
        date
      }
    }
  }
`;

export const JOB_STATUS = gql`
  mutation setJobStatus($setJobStatusInput: SetJobStatusInput!) {
    setJobStatus(setJobStatusInput: $setJobStatusInput) {
      id
      status
      statusHistory {
        status
        date
      }
    }
  }
`;

export const SET_JOB_HAS_BEEN_READ = gql`
  mutation setJobHasBeenRead($setJobHasBeenReadInput: SetJobHasBeenReadInput) {
    setJobHasBeenRead(setJobHasBeenReadInput: $setJobHasBeenReadInput) {
      id
      hasJobBeenRead
    }
  }
`;

export const SET_JOB_ATTRIBUTES = gql`
  mutation setJobAttributes($setJobAttributesInput: SetJobAttributesInput!) {
    setJobAttributes(setJobAttributesInput: $setJobAttributesInput)
  }
`;
