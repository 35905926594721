import React, { Component } from 'react';
import { object, string, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '../Card';
import LineItem from './LineItem';
import MinimunPayout from './MinimunPayout';
import { DEADPOOL_SPEC_ID } from '../../../constants';

const styles = {
  toggleButton: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    color: '#F96302',
    'align-self': 'flex-end',
  },
  h3: {
    margin: '0px',
  },
  wrapper: {
    padding: '0px 15px 15px 15px',
    'border-bottom': '1px solid #ccc',
    'margin-bottom': '15px',
  },
  bold: {
    fontWeight: 'bold',
    'margin-left': '0px',
    paddingTop: 10,
  },
  minimal: {
    'align-self': 'flex-end',
    fontSize: 14,
  },
};
class PayoutDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: true,
    };
  }

  static renderLineItems(charges) {
    return charges
      .reduce((result, { specId, value, specType, cost }) => {
        if (specType !== 'Surcharge' && specType !== 'BasePrice') return result;
        const quantity = value === 'true' || value === 'false' ? null : value;
        const hasSingleSaleServiceSpecId = specId === DEADPOOL_SPEC_ID ? 'Single Sale Services' : specId;
        result.push({ hasSingleSaleServiceSpecId, quantity, cost });
        return result;
      }, [])
      .map(({ hasSingleSaleServiceSpecId, quantity, cost }) => <LineItem key={hasSingleSaleServiceSpecId} display={hasSingleSaleServiceSpecId} quantity={quantity} cost={`$${cost.toFixed(2)}`} />);
  }

  static renderChangeOrder({ charges = [] }) {
    if (charges.length < 1) return null;
    return charges.map(({ specId, value }) => <LineItem key={specId} display={specId} cost={value} />);
  }

  toggleDetails() {
    this.setState(state => ({ showDetails: !state.showDetails }));
  }

  renderDetails() {
    const { initialPricingResult: { charges: bookedCharges, cost: bookedCost }, changeOrder, classes, isInstoreJob } = this.props;
    const bookedPayout = PayoutDetails.renderLineItems(bookedCharges);
    const changeOrderInfo = changeOrder ? PayoutDetails.renderChangeOrder(changeOrder) : null;
    return (
      <React.Fragment>
        <div className={classes.wrapper}>
          <h3 className={classes.h3}>Booked Payout</h3>
          {bookedPayout}
          <LineItem
            overrideStyles={classes.bold}
            display="Booked Payout"
            cost={`$${bookedCost.toFixed(2)}*`}
          />
        </div>
        {
          !isInstoreJob && changeOrderInfo && (
            <div className={classes.wrapper}>
              <h3 className={classes.h3}>Change Order</h3>
              {changeOrderInfo}
            </div>
          )
        }
      </React.Fragment>
    );
  }

  render() {
    const { pricingResult: { charges: finalCharges, cost: finalCost }, classes, pricingAgreementId, isLaborOnlyPo } = this.props;
    const { showDetails } = this.state;
    const finalPayout = PayoutDetails.renderLineItems(finalCharges);
    const payoutDetails = this.renderDetails();
    const actionsConfig = [
      {
        id: 'payout-details',
        renderAction: () => (
          <div
            onKeyUp={() => this.toggleDetails()}
            className={classes.toggleButton}
            onClick={() => this.toggleDetails()}
            role="button"
            tabIndex={0}
          >
            {showDetails ? 'Hide Details' : 'Show Details'}
          </div>
        ),
      },
    ];
    return (
      <Card title="Payout Details" actionsConfig={actionsConfig}>
        {showDetails && payoutDetails}
        <div className={classes.wrapper}>
          <h3 className={classes.h3}>Finalized Payout</h3>
          {finalPayout}
          <LineItem
            overrideStyles={classes.bold}
            display="Final Payout"
            cost={`$${finalCost.toFixed(2)}*`}
          />
        </div>
        {!isLaborOnlyPo && <div className={classes.minimal}><MinimunPayout id={pricingAgreementId} /></div>}
      </Card>
    );
  }
}

PayoutDetails.propTypes = {
  initialPricingResult: object.isRequired,
  pricingResult: object.isRequired,
  changeOrder: object,
  classes: object.isRequired,
  pricingAgreementId: string.isRequired,
  isLaborOnlyPo: bool,
  isInstoreJob: bool,
};

PayoutDetails.defaultProps = {
  changeOrder: null,
  isLaborOnlyPo: false,
  isInstoreJob: false,
};


export default withStyles(styles)(PayoutDetails);
